import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import EmailIcon from '@material-ui/icons/Email'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    }
  })
)

export default function FloatingActionButtons() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Fab
        component={'a'}
        aria-label="Email"
        href={
          'mailto:Alexisconsultancy@yahoo.com?subject=Alexis Consultancy Enquiry'
        }
        target={'__blank'}
        color="secondary"
      >
        <EmailIcon />
      </Fab>
      <Fab
        color="primary"
        aria-label="Twitter"
        component={'a'}
        href={'https://twitter.com/MichelleAlexi15'}
        target={'__blank'}
      >
        <TwitterIcon />
      </Fab>
      <Fab
        component={'a'}
        aria-label="LinkedIn"
        href={
          'https://www.linkedin.com/in/michelle-alexis-21269641/?originalSubdomain=uk'
        }
        target={'__blank'}
        color="secondary"
      >
        <LinkedInIcon />
      </Fab>
    </div>
  )
}
