import React from 'react'

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import styled from 'styled-components'
import FloatingActionButtons from './FloatingActionBtns'

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(26, 61, 165)'
    },
    secondary: {
      main: 'rgb(102, 136, 229)'
    }
  }
})

const Header = styled.header`
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default function Layout(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <Header>
        <FloatingActionButtons />
      </Header>

      {props.children}
    </ThemeProvider>
  )
}
