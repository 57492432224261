import React from 'react'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

const data = [
  {
    title: 'About',
    content: (
      <>
        Michelle Alexis has a wealth of experience in leadership positions,
        devising and implementing EDI strategy benefiting all protected
        characteristics.
      </>
    )
  },
  {
    title: 'Current projects',
    content: (
      <>
        Currently working on high level projects as Deputy Business Manager,
        Chair of Cardiff University Race Equality Steering Group and BAME+ Staff
        Network. She was previously Trustee and Chair of the Womens Equality
        Network for Wales for four years, has set up a number of Strategic
        Equality Boards, advised on and delivered talks on Diversifying Boards,
        named on the BME Powerlist for Bristol as a city in 2018 and is
        currently working with the Welsh Government on their Race Equality
        Action Plan. Michelle delivers sessions on empowerment and
        employability.
      </>
    )
  },
  {
    title: 'Diversity solutions',
    content:
      'Offering a wide range of advice to businesses on diversity and inclusion strategy in the workplace tailored to their needs.'
  },
  {
    title: 'Mentoring',
    content:
      'Offering individuals and groups empowering mentoring sessions to thrive'
  },
  {
    title: 'Employability',
    content: 'Navigating the workplace, delivery of interview skills workshops'
  },
  {
    title: 'Public Speaking',
    content:
      'Talks on diversifying boards, inclusive workforce, resilience, diverse solutions'
  }
]

export default function InfoAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>('panel1')

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <div>
      {data.map(({ title, content }, index) => (
        <Accordion
          style={{zIndex: 1, position: 'relative'}}
          key={title}
          square
          expanded={expanded === `panel${index + 1}`}
          onChange={handleChange(`panel${index + 1}`)}
        >
          <AccordionSummary
            color={'primary'}
            expandIcon={<ExpandMoreIcon color={'secondary'} />}
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
          >
            <Typography color={'textPrimary'}>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color={'textSecondary'}>{content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}
