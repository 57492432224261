import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Logo from './../images/logo.png'
import Box from '@material-ui/core/Box'
import { Link } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 'auto',
    zIndex: 1,
    position: 'relative'
  }
})

export default function ContactCard() {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Michelle Alexis"
          height="150"
          image={Logo}
          title="Michelle Alexis"
        />
        <CardContent>
          <Box textAlign={'right'}>
            <Typography gutterBottom variant="body1" component="em">
              Evolve - Empower - Enrich
            </Typography>
            <br />
            <br />
            <br />
            <Typography variant="body1" color="textSecondary" component="p">
              Michelle Alexis
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Email:{' '}
              <Link
                href={'mailto:Alexisconsultancy@yahoo.com'}
                target={'__blank'}
              >
                Alexisconsultancy@yahoo.com
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
