import React from 'react'
// @ts-ignore
import ReactFullpage from '@fullpage/react-fullpage'
import styled from 'styled-components'
import Layout from './component/Layout'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Container from '@material-ui/core/Container'
import { FloatingCircle } from './component/FloatingCircle'
import InfoAccordion from './component/InfoAccordion'
import ContactCard from './component/ContactCard'

export const pallete = ['rgb(26, 61, 165)', 'rgb(102, 136, 229)']

const Title = styled.div`
  @media only screen and (max-width: 600px) {
    .title {
      font-size: 1.25em;
    }
    .subtitle {
      font-size: 1.5em;
    }
  }
`

const Page = styled.div`
  @media only screen and (max-width: 600px) {
    &.main {
      text-align: center;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`

const Text: any = styled(Typography)`
  z-index: 1;
  position: relative;
`

export default function App() {

  return (
    <Layout>
      <ReactFullpage
        // debug /* Debug logging */
        scrollingSpeed={1000} /* Options here */
        licenseKey={'D7981F44-3DFE40C6-927003F8-F79B58F2'}
        // onLeave={(obj: any) => console.log(obj.index)}
        anchors={['home', 'about', 'contact']}
        sectionsColor={pallete}
        render={() => (
          <ReactFullpage.Wrapper>
            <div className="section">
              <Page className={'main'}>
                <Container maxWidth="sm">
                  <Box>
                    <FloatingCircle
                      className={'ball'}
                      data-position={{
                        top: '20%',
                        left: '10%',
                        height: 150,
                        width: 150
                      }}
                    />
                    <FloatingCircle
                      className={'ball'}
                      data-position={{
                        bottom: '25%',
                        right: '20%',
                        height: 200,
                        width: 200
                      }}
                    />
                    <FloatingCircle
                      className={'ball'}
                      data-position={{
                        top: '5%',
                        right: '5%',
                        height: 250,
                        width: 250
                      }}
                    />
                    <Title>
                      <Text as={'h1'} variant={'h2'}>
                        <Box
                          className={'title'}
                          fontWeight={200}
                          component={'div'}
                          fontSize={40}
                          style={{ letterSpacing: '15px' }}
                        >
                          Alexis
                        </Box>
                        <Box
                          fontWeight={700}
                          component={'div'}
                          fontSize={80}
                          className={'subtitle'}
                        >
                          Consultancy
                        </Box>
                      </Text>
                    </Title>
                  </Box>
                  <br />
                  <Box>
                    <Text component={'h2'} variant={'subtitle1'}>
                      Evolve - Empower - Enrich
                    </Text>
                  </Box>
                  <br />
                  <br />

                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      size={'large'}
                      href="#about"
                    >
                      <Box fontWeight={700}>About</Box>
                    </Button>
                    <Button
                      variant={'contained'}
                      color={'inherit'}
                      size={'large'}
                      href="#contact"
                    >
                      <Typography color={'secondary'}>
                        <Box fontWeight={700}>Contact</Box>
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Container>
              </Page>
            </div>
            <div className="section">
              <Page>
                <Container maxWidth="sm">
                  <InfoAccordion />
                </Container>
              </Page>
            </div>
            <div className="section">
              <Page>
                <Container maxWidth="sm">
                  <ContactCard />
                </Container>
              </Page>
            </div>
          </ReactFullpage.Wrapper>
        )}
      />
    </Layout>
  )
}
