import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'

type PropTypes = Record<string, any>

const Circle = styled.div`
  width: 200px;
  height: 200px;
  background-color: rgb(102, 136, 229);

  ${(props: any) => {
    return props['data-position']
  }};
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  :hover img {
    opacity: 1;
  }

  img {
    transition: all 0.2s;
    width: inherit;
    height: inherit;
    opacity: 0.15;
    object-position: center;
    object-fit: cover;
  }
`

export const FloatingCircle: React.FC<PropTypes> = ({ src, ...props }) => {
  useEffect(() => {
    gsap.to('.ball', {
      opacity: 0.7,
      duration: 4.5,
      repeat: -1,
      stagger: {
        each: 0.5,
        grid: 'auto',
        from: 'center'
      },
      x: 'random(-20, 20, 2)', //chooses a random number between -20 and 20 for each target, rounding to the closest 5!
      y: 'random(-20, 20, 2)', //chooses a random number between -20 and 20 for each target, rounding to the closest 5!
      ease: 'power1.inOut',
      repeatRefresh: true,
  })
  }, [])

  return (
    <Circle className={'ball'} {...props}>
      {src && <img src={src} />}
    </Circle>
  )
}

export default FloatingCircle
